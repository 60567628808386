/* stylelint-disable */
html,
body,
h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
span,
em,
small,
strong,
sub,
sup,
mark,
del,
ins,
strike,
abbr,
dfn,
blockquote,
q,
cite,
code,
pre,
ol,
ul,
li,
dl,
dt,
dd,
div,
section,
article,
main,
aside,
nav,
header,
hgroup,
footer,
img,
figure,
figcaption,
address,
time,
audio,
video,
canvas,
iframe,
details,
summary,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
}

html {
  tab-size: 2;
  -webkit-text-size-adjust: 100%;
}

body {
  overflow: scroll;
}

body * {
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
  letter-spacing: inherit;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-weight: normal;
}

a,
a:visited {
  color: inherit;
  text-decoration: none;
  background-color: transparent;
  -webkit-tap-highlight-color: transparent;
}

article,
aside,
footer,
header,
nav,
section,
main {
  display: block;
}

* {
  box-sizing: border-box;
}

*:before,
*:after {
  box-sizing: inherit;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
}

ol,
ul {
  list-style: none;
}

img,
video {
  max-width: 100%;
}

img {
  border-style: none;
}

blockquote,
q {
  quotes: none;
}

blockquote:after,
blockquote:before,
q:after,
q:before {
  content: '';
  content: none;
}

b,
strong {
  font-weight: bold;
}

textarea {
  overflow: auto;
  resize: vertical;
}

[hidden] {
  display: none !important;
}

[disabled] {
  cursor: not-allowed;
}

:focus:not(:focus-visible) {
  outline: none;
}

input:invalid {
  box-shadow: none;
}

input:invalid,
textarea:invalid,
select:invalid {
  box-shadow: none;
}

button {
  outline: none;
}

.sr-only {
  position: absolute;
  clip: rect(1px, 1px, 1px, 1px);
  left: -9999px;
  top: -9999px;
}

@keyframes onAutoFillStart {
  from {
  }
  to {
  }
}
input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  animation-name: onAutoFillStart;
  transition: background-color 50000s ease-in-out 0s;
}
